<template>
  <div
    class="
      bg-[#ffffff1a]
      rounded-24px
      p-20px
      backdrop-filter backdrop-blur-20px
    "
  >
    <div class="flex justify-between">
      <div class="text-23px font-bold">{{ coin }}</div>
      <div class="text-right">
        <div class="text-15px font-Gilroy">{{balance}} {{ coin }}</div>
        <div class="text-[#ffffff4d] text-13px mt-4px">{{ $t("head.balance") }}</div>
      </div>
    </div>
    <div class="border border-[#00000033] rounded-full mt-16px">
      <slot></slot>
    </div>
    <div class="mt-16px">
      <div
        class="
          bg-gradient-to-l
          to-[#2586de]
          from-[#e33bd8]
          rounded-16px
          h-44px
          leading-44px
          text-center
        "
        @click="approve"
      >
        {{ $t("head.approve") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String,Number],
      default: "",
    },
    coin: {
      type: String,
      default: "",
    },
    balance: {
      type: [String, Number],
      default: "",
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("change",parseFloat(e))
        this.$emit("input", parseFloat(e));
      },
    },
  },
  methods:{
    approve(){
      this.$emit("approve", this.val);
    },
    stake(){
      this.$emit("stake", this.val);
    },
  }
};
</script>
