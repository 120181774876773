<template>
  <div class="relative page-bg-gradient min-h-full">
    <Headers></Headers>
    <div
      class="
        absolute
        top-31px
        right-0
        w-138px
        h-207px
        bg-[#ff56f599]
        !filter
        !blur-100px
        z-99
      "
    ></div>
    <div
      class="
        absolute
        top-294px
        left-0
        w-138px
        h-207px
        bg-[#2586deb3]
        !filter
        !blur-100px
        z-99
      "
    ></div>
    <div class="pt-92px">
      <img
        class="w-96px h-96px mx-auto"
        src="~@/assets/new/USDT.png"
        alt=""
        srcset=""
      />
      <div class="-mt-15px text-center text-21px font-medium">
        {{ bilv.title }}
      </div>
      <div class="mt-8px text-center">
        <div
          v-if="bilv.suanli"
          class="
            inline-block
            border border-[#ffffff33] border-solid
            rounded-200px
            h-20px
            px-10px
            text-12px
            leading-20px
          "
        >
          {{ bilv.suanli }}
        </div>
      </div>
      <div class="mt-17px px-24px">
        <div
          class="
            bg-[#ffffff1a]
            rounded-24px
            backdrop-filter backdrop-blur-20px
            p-20px
          "
        >
          <div
            class="flex justify-between text-15px py-5px"
            v-if="
              bilv.tokenaddress.toLowerCase() ==
              bilv.tokenaddress1.toLowerCase()
            "
          >
            <div class="text-[#ffffffb3]">Deposit</div>
            <div class="text-text1">{{ deposit }}</div>
          </div>

          <template v-else>
            <div class="flex justify-between text-15px py-5px">
              <div class="text-[#ffffffb3]">Deposit {{ bilv.tokenname }}</div>
              <div class="text-text1">{{ deposit1.amountA }}</div>
            </div>
            <div class="flex justify-between text-15px py-5px">
              <div class="text-[#ffffffb3]">Deposit UNC</div>
              <div class="text-text1">{{ deposit1.amountB }}</div>
            </div>
          </template>

          <div class="flex justify-between text-15px py-5px">
            <div class="text-[#ffffffb3]">Hash Power</div>
            <div class="text-text1">{{ Hash }}</div>
          </div>
          <div class="flex justify-between text-15px py-5px" v-if="!isMTC">
            <div class="text-[#ffffffb3]">Earn</div>
            <div class="text-text1 font-extrabold italic">
              {{ poolearn }} UNC
            </div>
          </div>
          <div class="flex justify-between text-15px py-5px" v-if="!isMTC">
            <div class="text-[#ffffffb3]">APR(Max)</div>
            <div class="text-text1">{{ apr }} %</div>
          </div>
        </div>

        <div
          class="
            rounded-200px
            bg-[#ffffff03]
            box-shadow
            mt-16px
            py-9px
            px-12px
            flex
          "
          v-if="
            bilv.tokenaddress.toLowerCase() != bilv.tokenaddress1.toLowerCase()
          "
        >
          <img
            src="~@/assets/new/07.png"
            class="w-28px h-30px -mb-8px"
            alt=""
            srcset=""
          />
          <div class="text-[#FFE600] text-13px h-22px leading-22px ml-6px">
            {{ $t("head.willGetPower") }}:
            <span class="font-Gilroy font-800 italic">{{ sl }}</span>
          </div>
        </div>
        <div class="mt-24px text-21px font-medium">{{$t('head.pledgeRate')}}</div>
        <div class="py-20px">
          <ItemComp
            :coin="bilv.tokenname"
            class="mb-20px"
            :balance="balance"
            :flag="flag"
            @approve="
              bilv.tokenaddress.toLowerCase() ==
              bilv.tokenaddress1.toLowerCase()
                ? approve2()
                : approve1()
            "
          >
            <input
              @input="inp1(bilv.amount)"
              class="
                rounded-full
                inp
                w-full
                bg-transparent
                h-40px
                px-10px
                bg-[#ffffff73]
              "
              v-model="bilv.amount"
              label-width="0"
              :placeholder="$t('head.inputAmount')"
            />
          </ItemComp>
          <template
            v-if="
              bilv.tokenaddress.toLowerCase() !=
              bilv.tokenaddress1.toLowerCase()
            "
          >
            <ItemComp
              :coin="bilv.tokenname1"
              class="mb-20px"
              :flag="flag1"
              :balance="tokenbalance"
              @approve="approve"
            >
              <input
                @input="inp2(bilv.amount1)"
                class="
                  rounded-full
                  inp
                  w-full
                  bg-transparent
                  h-40px
                  px-10px
                  bg-[#ffffff73]
                "
                v-model="bilv.amount1"
                label-width="0"
                :placeholder="$t('head.inputAmount')"
              />
            </ItemComp>
          </template>
          <div
            v-if="
              bilv.tokenaddress.toLowerCase() ==
              bilv.tokenaddress1.toLowerCase()
                ? !flag
                : !flag && !flag1
            "
            class="
              bg-gradient-to-l
              to-[#1453bf]
              from-[#24d68e]
              rounded-16px
              h-44px
              leading-44px
              text-center
            "
            @click="onSubmit"
          >
            {{ $t("head.stake") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers";
import mixin from "@/mixins/index";
import { BigNumber } from "bignumber.js";
import ItemComp from "./components/itemComp";
export default {
  data() {
    return {
      bilv: {
        pid: "",
        amount: "",
        amount1: "",
        rid: "0",
        token: "",
        tokenname: "",
        tokenname1: "",
        tokenaddress: "",
        tokenaddress1: "",
        suanli: "",
        title: "",
        img1: "",
        img2: "",
        decimals0: "",
        decimals1: "",
      },
      sl: "0%",
      apr: "",
      poolearn: "",
      deposit: "0",
      deposit1: {},
      Hash: "",
      balance: "0",
      tokenbalance: "0",
      loading: false,
      loading1: false,
      flag: true,
      flag1: true,
      jiazhi0: {},
      jiazhi: {},
      jiazhi1: {},
      jiazhi2: {},
      zonggong: "",
      tokenOption: {},
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },
  computed: {
    proportions() {
      const arr = this?.tokenOption?.proportions?.split(",") ?? [];
      return arr;
    },
    minWeight() {
      const arr = this?.tokenOption?.minWeight?.split(",") ?? [];
      return arr;
    },
    isMTC() {
      return this.bilv.tokenname == "MTC";
    },
  },
  watch: {
    "global.account"() {
      console.log(this.$route.query);
      this.bilv.pid = this.$route.query.pid;
      this.bilv.token = this.$route.query.token;
      this.bilv.tokenname = this.$route.query.tokenname;
      this.bilv.tokenname1 = this.$route.query.tokenname1;
      this.bilv.suanli = this.$route.query.suanli;
      this.bilv.tokenaddress = this.$route.query.tokenaddress;
      this.bilv.tokenaddress1 = this.$route.query.tokenaddress1;
      this.bilv.title = this.$route.query.title;
      this.bilv.img1 = this.$route.query.img1;
      this.bilv.img2 = this.$route.query.img2;
      this.bilv.decimals0 = this.$route.query.decimals0;
      this.bilv.decimals1 = this.$route.query.decimals1;
      // switch (this.bilv.rid) {
      //   case "0":
      //     this.sl = "100%";
      //     break;
      //   case "1":
      //     this.sl = "120%";
      //     break;
      //   case "2":
      //     this.sl = "150%";
      //     break;
      // }

      // if(this.bilv.tokenname == 'MTC'){
      //   this.pool.iphpool2 = this.pool.MTC4Pool2
      //   this.poolabi.iphpool_abi2 = this.poolabi.MTC4Pool2_abi
      // }

      this.IPH_contract = new this.global.web3.eth.Contract(
        this.poolabi.iph_abi,
        this.pool.iph
      );
      this.IPHPool2_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi2,
        this.pool.iphpool2
      );
      this.MTC4Pool2_contract = new this.global.web3.eth.Contract(
        this.poolabi.MTC4Pool2_abi,
        this.pool.MTC4Pool2
      );
      this.token_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.bilv.tokenaddress
      );
      this.token_contract1 = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.bilv.tokenaddress1
      );
      console.log(this.token_contract, "this.token_contract");
      this.getdeposit();
      this.getbalance();
    },
    "bilv.rid"(e) {
      this.sl = this.minWeight[parseInt(e)] + "%";
      this.bilv.amount = "";
      this.bilv.amount1 = "";
    },
  },
  methods: {
    getData() {
      const { token } = this.$route.query;
      this.$axios
        .get(`${this.listdata}/pool/list`)
        .then(({ data: { data } }) => {
          const obj = data.find(
            (e) => `${e.name0}-${e.name1}`.toLowerCase() == token.toLowerCase()
          );
          this.tokenOption = obj;
          if(this.isMTC){
            this.sl = '100%'
          }else{
            this.sl = this.minWeight[parseInt(this.bilv.rid)] + "%";
          }

        });
    },
    inp1(item) {
      let baifen = 0;

      switch (this.bilv.rid) {
        case "0":
          baifen = 0.5;
          break;
        case "1":
          baifen = 0.6;
          break;
        case "2":
          baifen = 0.7;
          break;
      }
      this.zonggong = (item * this.jiazhi[1]) / this.jiazhi[0] / baifen;
      console.log(
        `${item} * ${this.jiazhi[1]} / ${this.jiazhi[0]} / ${baifen}`
      );
      this.bilv.amount1 = (
        ((item * this.jiazhi[1]) / baifen) *
        (1 - baifen) *
        this.jiazhi1[1]
      ).toFixed(8);
      console.log(this.bilv.amount1, "this.bilv.amount1");
      if (
        this.bilv.tokenaddress.toLowerCase() ==
        this.bilv.tokenaddress1.toLowerCase()
      ) {
        this.zonggong = (item * this.jiazhi[1]) / this.jiazhi[0];
      }

      console.log(this.zonggong, "this.zonggong1");
      if (item == "") {
        this.bilv.amount1 = "";
      }
    },

    inp2(item) {
      console.log("===========");
      let baifen = 0;

      switch (this.bilv.rid) {
        case "0":
          baifen = 0.5;
          break;
        case "1":
          baifen = 0.4;
          break;
        case "2":
          baifen = 0.3;
          break;
      }
      this.zonggong = (item * this.jiazhi2[1]) / this.jiazhi2[0] / baifen;
      console.log(this.zonggong, "this.zonggong2");

      console.log(
        `${item} * ${this.jiazhi2[1]} / ${this.jiazhi2[0]} / ${baifen}`
      );
      this.bilv.amount =
        ((item * this.jiazhi2[1]) / baifen) * (1 - baifen) * this.jiazhi0[1];

      console.log(this.bilv.amount, "this.bilv.amount");
      if (item == "") {
        this.bilv.amount = "";
      }
    },
    approve2() {
      if (this.bilv.amount == "") return this.$toast(this.$t("head.sl"));
      console.log("decimals", this.bilv.decimals0);
      const price = (
        this.bilv.amount *
        10 ** this.bilv.decimals0 *
        100
      ).toFixed(0);
      this.loading = true;

      let currentToken = this.pool.iphpool2;
      if (this.isMTC) {
        currentToken = this.pool.MTC4Pool2;
      }
      console.log(this.bilv.tokenname);

      this.token_contract1.methods
        .approve(
          currentToken,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          this.loading = false;
          this.flag = false;
          this.$toast("APPROVE SUCCESS");
        })
        .catch((err) => {
          this.$toast(err);
          this.loading = false;
        });
    },
    approve() {
      console.log(this.bilv.tokenname);
      console.log("decimals", this.bilv.decimals1);
      if (this.bilv.amount == "") return this.$toast(this.$t("head.sl"));
      this.loading = true;
      const price = (
        this.bilv.amount1 *
        100 *
        10 ** this.bilv.decimals1
      ).toFixed(0);

      let currentToken = this.pool.iphpool2;
      if (this.isMTC) {
        currentToken = this.pool.MTC4Pool2;
      }

      this.token_contract1.methods
        .approve(
          currentToken,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          this.loading = false;
          this.flag = false;
          this.$toast("APPROVE SUCCESS");
        })
        .catch((err) => {
          this.$toast(err);
          this.loading = false;
        });
    },
    approve1() {
      console.log(this.bilv.tokenname);
      console.log("decimals", this.bilv.decimals0);
      if (this.bilv.amount == "") return this.$toast(this.$t("head.sl"));
      console.log(this.bilv.amount);
      const price = (
        this.bilv.amount *
        10 ** this.bilv.decimals0 *
        100
      ).toFixed(0);
      this.loading1 = true;

      let currentToken = this.pool.iphpool2;
      if (this.isMTC) {
        currentToken = this.pool.MTC4Pool2;
      }

      this.token_contract.methods
        .approve(
          currentToken,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          this.loading1 = false;
          this.flag1 = false;
          this.$toast(`${this.bilv.tokenname} APPROVE SUCCESS`);
        })
        .catch((err) => {
          this.$toast(err);
          this.loading1 = false;
        });
    },

    onSubmit() {
      if (this.bilv.amount == "") return this.$toast(this.$t("head.sl"));
      this.loading = true;
      console.log(`pid:${this.bilv.pid}`);
      console.log(
        `amount:${this.global.web3.utils.toWei(this.zonggong + "", "ether")}`
      );
      console.log(`rid:${this.bilv.rid}`);
      console.log(`contract:${this.IPHPool2_contract._address}`);
      console.log(`account:${this.global.account}`);

      if (this.isMTC) {
        this.MTC4Pool2_contract.methods
          .deposit(this.global.web3.utils.toWei(this.zonggong + "", "ether"))
          .send({ from: this.global.account })
          .then(() => {
            this.loading = false;
            this.flag = true;
            this.flag1 = true;
            this.$toast("SUCCESS");
            this.$router.push("/");
          })
          .catch((err) => {
            this.$toast(err);
            this.loading = false;
          });
      } else {
        this.IPHPool2_contract.methods
          .deposit(
            this.bilv.pid,
            this.global.web3.utils.toWei(this.zonggong + "", "ether"),
            this.bilv.rid
          )
          .send({
            from: this.global.account,
          })
          .then((res) => {
            this.loading = false;
            this.flag = true;
            this.flag1 = true;
            this.$toast("SUCCESS");
            this.$router.push("/");
          })
          .catch((err) => {
            this.$toast(err);
            this.loading = false;
          });
      }
    },

    getdeposit() {
      if (this.isMTC) {
        this.MTC4Pool2_contract.methods
          .getDeposit(this.global.account)
          .call()
          .then((res) => {
            this.Hash = (res.amountR / 1e18).toFixed(6);
            this.deposit =
              res.amountA / 10 ** this.bilv.decimals0 +
              res.amountB / 10 ** this.bilv.decimals1;
            this.deposit1.amountA = (
              res.amountA /
              10 ** this.bilv.decimals0
            ).toFixed(6);
            this.deposit1.amountB = (
              res.amountB /
              10 ** this.bilv.decimals1
            ).toFixed(6);
            if (res.amountA == 0 && res.amountB == 0) {
              this.deposit = 0;
              this.deposit1.amountA = 0;
              this.deposit1.amountB = 0;
            }

            if (res.amountR == 0) {
              this.Hash = 0;
            }
          });
      } else {
        this.IPHPool2_contract.methods
          .getDeposit(this.global.account, this.bilv.pid)
          .call()
          .then((res) => {
            this.Hash = (res.amountR / 1e18).toFixed(6);
            this.deposit =
              res.amountA / 10 ** this.bilv.decimals0 +
              res.amountB / 10 ** this.bilv.decimals1;
            this.deposit1.amountA = (
              res.amountA /
              10 ** this.bilv.decimals0
            ).toFixed(6);
            this.deposit1.amountB = (
              res.amountB /
              10 ** this.bilv.decimals1
            ).toFixed(6);
            if (res.amountA == 0 && res.amountB == 0) {
              this.deposit = 0;
              this.deposit1.amountA = 0;
              this.deposit1.amountB = 0;
            }

            if (res.amountR == 0) {
              this.Hash = 0;
            }
          });
      }

      this.IPHPool2_contract.methods
        .APR(this.bilv.pid)
        .call()
        .then((res) => {
          this.apr = ((res.yopt / res.cic) * 100).toFixed(6);
          if (this.apr == "Infinity" || this.apr == "NaN") {
            this.apr = 0;
          }
        });

      this.IPHPool2_contract.methods
        .poolEarned(this.global.account, this.bilv.pid)
        .call()
        .then((res) => {
          this.poolearn = res / 1e18;
        });
      this.IPHPool2_contract.methods //价值
        .getPrice(this.pool.USDT, this.bilv.tokenaddress)
        .call()
        .then((res) => {
          // console.log(this.bilv.tokenaddress);
          // console.log(this.bilv.decimals0);
          res[0] = res[0] / 1e18;
          res[1] = res[1] / 10 ** this.bilv.decimals0;
          console.log(this.bilv.decimals0);
          this.jiazhi0 = res;
          console.log(`USDT:${this.bilv.tokenname}---${res[0]}:${res[1]}`);
        });
      this.IPHPool2_contract.methods //价值
        .getPrice(this.bilv.tokenaddress, this.pool.USDT)
        .call()
        .then((res) => {
          // console.log(res);
          res[0] = res[0] / 10 ** this.bilv.decimals0;
          res[1] = res[1] / 1e18;
          this.jiazhi = res;
          console.log(`${this.bilv.tokenname}:USDT---${res[0]}:${res[1]}`);
        });
      this.IPHPool2_contract.methods
        .getPrice(this.pool.USDT, this.bilv.tokenaddress1)
        .call()
        .then((res) => {
          // console.log(this.bilv.decimals1);
          res[0] = res[0] / 1e18;
          res[1] = res[1] / 10 ** this.bilv.decimals1;
          this.jiazhi1 = res;
          console.log(`USDT:${this.bilv.tokenname1}---${res[0]}:${res[1]}`);
        });
      this.IPHPool2_contract.methods
        .getPrice(this.bilv.tokenaddress1, this.pool.USDT)
        .call()
        .then((res) => {
          res[0] = res[0] / 10 ** this.bilv.decimals1;
          res[1] = res[1] / 1e18;
          this.jiazhi2 = res;
          console.log(`${this.bilv.tokenname1}:USDT---${res[0]}:${res[1]}`);
        });
    },
    getbalance() {
      this.token_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          console.log(res, "resres");
          this.balance = (res / 10 ** this.bilv.decimals0).toFixed(6);
          if (res == 0) {
            this.balance = 0;
          }
        });
      this.token_contract1.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          console.log(res, "resresresres");
          this.tokenbalance = (res / 10 ** this.bilv.decimals1).toFixed(6);
          if (res == 0) {
            this.tokenbalance = 0;
          }
        });
    },
  },

  components: {
    Headers,
    ItemComp,
  },
};
</script>

<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0px 0px 20px #ff990066 inset;
}
</style>
