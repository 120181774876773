var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative page-bg-gradient min-h-full"},[_c('Headers'),_c('div',{staticClass:"absolute top-31px right-0 w-138px h-207px bg-[#ff56f599] !filter !blur-100px z-99"}),_c('div',{staticClass:"absolute top-294px left-0 w-138px h-207px bg-[#2586deb3] !filter !blur-100px z-99"}),_c('div',{staticClass:"pt-92px"},[_c('img',{staticClass:"w-96px h-96px mx-auto",attrs:{"src":require("@/assets/new/USDT.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"-mt-15px text-center text-21px font-medium"},[_vm._v(" "+_vm._s(_vm.bilv.title)+" ")]),_c('div',{staticClass:"mt-8px text-center"},[(_vm.bilv.suanli)?_c('div',{staticClass:"inline-block border border-[#ffffff33] border-solid rounded-200px h-20px px-10px text-12px leading-20px"},[_vm._v(" "+_vm._s(_vm.bilv.suanli)+" ")]):_vm._e()]),_c('div',{staticClass:"mt-17px px-24px"},[_c('div',{staticClass:"bg-[#ffffff1a] rounded-24px backdrop-filter backdrop-blur-20px p-20px"},[(
            _vm.bilv.tokenaddress.toLowerCase() ==
            _vm.bilv.tokenaddress1.toLowerCase()
          )?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit))])]):[_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit "+_vm._s(_vm.bilv.tokenname))]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountA))])]),_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit UNC")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountB))])])],_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Hash Power")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.Hash))])]),(!_vm.isMTC)?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Earn")]),_c('div',{staticClass:"text-text1 font-extrabold italic"},[_vm._v(" "+_vm._s(_vm.poolearn)+" UNC ")])]):_vm._e(),(!_vm.isMTC)?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("APR(Max)")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.apr)+" %")])]):_vm._e()],2),(
          _vm.bilv.tokenaddress.toLowerCase() != _vm.bilv.tokenaddress1.toLowerCase()
        )?_c('div',{staticClass:"rounded-200px bg-[#ffffff03] box-shadow mt-16px py-9px px-12px flex"},[_c('img',{staticClass:"w-28px h-30px -mb-8px",attrs:{"src":require("@/assets/new/07.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"text-[#FFE600] text-13px h-22px leading-22px ml-6px"},[_vm._v(" "+_vm._s(_vm.$t("head.willGetPower"))+": "),_c('span',{staticClass:"font-Gilroy font-800 italic"},[_vm._v(_vm._s(_vm.sl))])])]):_vm._e(),_c('div',{staticClass:"mt-24px text-21px font-medium"},[_vm._v(_vm._s(_vm.$t('head.pledgeRate')))]),_c('div',{staticClass:"py-20px"},[_c('ItemComp',{staticClass:"mb-20px",attrs:{"coin":_vm.bilv.tokenname,"balance":_vm.balance,"flag":_vm.flag},on:{"approve":function($event){_vm.bilv.tokenaddress.toLowerCase() ==
            _vm.bilv.tokenaddress1.toLowerCase()
              ? _vm.approve2()
              : _vm.approve1()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bilv.amount),expression:"bilv.amount"}],staticClass:"rounded-full inp w-full bg-transparent h-40px px-10px bg-[#ffffff73]",attrs:{"label-width":"0","placeholder":_vm.$t('head.inputAmount')},domProps:{"value":(_vm.bilv.amount)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.bilv, "amount", $event.target.value)},function($event){return _vm.inp1(_vm.bilv.amount)}]}})]),(
            _vm.bilv.tokenaddress.toLowerCase() !=
            _vm.bilv.tokenaddress1.toLowerCase()
          )?[_c('ItemComp',{staticClass:"mb-20px",attrs:{"coin":_vm.bilv.tokenname1,"flag":_vm.flag1,"balance":_vm.tokenbalance},on:{"approve":_vm.approve}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bilv.amount1),expression:"bilv.amount1"}],staticClass:"rounded-full inp w-full bg-transparent h-40px px-10px bg-[#ffffff73]",attrs:{"label-width":"0","placeholder":_vm.$t('head.inputAmount')},domProps:{"value":(_vm.bilv.amount1)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.bilv, "amount1", $event.target.value)},function($event){return _vm.inp2(_vm.bilv.amount1)}]}})])]:_vm._e(),(
            _vm.bilv.tokenaddress.toLowerCase() ==
            _vm.bilv.tokenaddress1.toLowerCase()
              ? !_vm.flag
              : !_vm.flag && !_vm.flag1
          )?_c('div',{staticClass:"bg-gradient-to-l to-[#1453bf] from-[#24d68e] rounded-16px h-44px leading-44px text-center",on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t("head.stake"))+" ")]):_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }